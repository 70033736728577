import { FinancialDocumentLog } from '../../graphql/__generated__/FinancialDocumentLog.fragment';
import { Log } from '../Log.types';

export const FINANCIAL_DOCUMENT_LOG_TYPES = [
  'FinancialDocumentImportedLog',
  'FinancialDocumentEmailSentLog',
];

export function isFinancialDocumentLog(log: Log): log is FinancialDocumentLog {
  return FINANCIAL_DOCUMENT_LOG_TYPES.includes(log.__typename);
}
