import { ReactNode, useEffect } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from '@hs-baumappe/legacy-ui';
import { FormikFormField } from '@hs-baumappe/forms';
import { AddContactPersonFormValues } from './AddContactForm.types';
import addContactPersonFormValidationSchema from './addContactPersonFormValidationSchema';
import AddContactPersonFormPhones from '../AddContactPersonFormPhones';

function addContactPersonFormInitialValues(
  initialValues?: Partial<AddContactPersonFormValues>,
): AddContactPersonFormValues {
  return {
    note: initialValues?.note ?? '',
    phones: initialValues?.phones ?? [''],
    name: initialValues?.name ?? '',
    email: initialValues?.email ?? '',
  };
}

interface AddContactPersonFormProps {
  onSubmit: (
    values: AddContactPersonFormValues,
    formikHelpers: FormikHelpers<AddContactPersonFormValues>,
  ) => void;
  initialValues?: Partial<AddContactPersonFormValues>;
  onRemoveButtonClick?: () => void;
  onFormDirty?: () => void;
  submitButtonText: ReactNode;
  submitButtonIcon?: ReactNode;
}

export default function AddContactPersonForm({
  onSubmit,
  initialValues,
  onRemoveButtonClick,
  onFormDirty,
  submitButtonText,
  submitButtonIcon = <Icon name="add" />,
}: AddContactPersonFormProps): JSX.Element {
  const { t } = useTranslation();
  const formik = useFormik<AddContactPersonFormValues>({
    onSubmit: (values, formikHelpers) => {
      onSubmit(
        {
          email: values.email || undefined,
          name: values.name || undefined,
          phones: values.phones || [],
          note: values.note || undefined,
        },
        formikHelpers,
      );
    },
    validationSchema: addContactPersonFormValidationSchema,
    initialValues: addContactPersonFormInitialValues(initialValues),
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
  });

  const { dirty } = formik;

  useEffect(() => {
    if (dirty && onFormDirty) {
      onFormDirty();
    }
  }, [dirty, onFormDirty]);

  return (
    <FormikProvider value={formik}>
      <Form>
        <div className="row u-margin-top-small">
          <div className="col col--sm-8">
            <div className="row">
              <div className="col col--sm-6 u-margin-top-small">
                <FormikFormField
                  name="name"
                  label={t('contactPeople.name.label')}
                  placeholder={t('contactPeople.name.placeholder')}
                />
              </div>
              <div className="col col--sm-6 u-margin-top-small">
                <FormikFormField
                  name="note"
                  label={t('contactPeople.note.label')}
                  placeholder={t('contactPeople.note.placeholder')}
                />
              </div>
              <div className="col col--sm-6 u-margin-top-small">
                <FormikFormField
                  name="email"
                  label={t('contactPeople.email.label')}
                  placeholder={t('contactPeople.email.placeholder')}
                />
              </div>
              <div className="col col--sm-6 u-margin-top-small">
                <AddContactPersonFormPhones />
              </div>
            </div>
          </div>
          <div className="col col--sm-4 u-margin-top-medium">
            <Button
              type="submit"
              color="primary"
              endIcon={submitButtonIcon}
              data-testid="add-a-person-button"
              fullWidth
            >
              {submitButtonText}
            </Button>

            {onRemoveButtonClick && (
              <Button
                variant="text"
                type="button"
                color="error"
                className="u-margin-top-small"
                onClick={onRemoveButtonClick}
                fullWidth
              >
                {t('removeContactPeople.removeButton')}
              </Button>
            )}
          </div>
        </div>
      </Form>
    </FormikProvider>
  );
}
