import { StylexProps } from '@hs-baumappe/legacy-stylex';
import { AcceptanceReportStatus } from '../../../../../globalTypes';
import { AcceptanceReport } from '../../../graphql/__generated__/AcceptanceReport.fragment';

export function getStatusColor(
  acceptanceReport: Pick<AcceptanceReport, 'status' | 'hasDefect' | 'defect'>,
): StylexProps['color'] {
  switch (acceptanceReport.status) {
    case AcceptanceReportStatus.OPEN:
      return 'muted';
    case AcceptanceReportStatus.ARCHIVED:
      return 'warning';
    case AcceptanceReportStatus.SIGNED:
    case AcceptanceReportStatus.MANUALLY_SIGNED: {
      if (acceptanceReport.hasDefect && acceptanceReport.defect) {
        return acceptanceReport.defect.agreed ? 'yellow-900' : 'error';
      }

      return 'success';
    }

    default:
      return 'success';
  }
}
