import { Text } from '@hs-baumappe/legacy-ui';
import { AppHeader } from '@hs-baumappe/frontend-kit';
import { useViewer } from '@hs-baumappe/web-auth';
import { useTranslation } from 'react-i18next';
import DocumentDetailBreadcrumbs from '../../../../../components/DocumentDetailBreadcrumbs';
import { DriveDocumentParentFolders } from '../../../../../hooks/useDriveDocumentParentFolders';
import { AcceptanceReport } from '../../../graphql/__generated__/AcceptanceReport.fragment';
import { AcceptanceReportProject } from '../../../graphql/__generated__/AcceptanceReportProject.fragment';
import routes from '../../../../routes';
import { getStatusColor } from './AcceptanceReportDetailHeader.utils';

interface AcceptanceReportDetailHeaderProps {
  acceptanceReport: AcceptanceReport;
  project: AcceptanceReportProject;
  parentFolders: DriveDocumentParentFolders;
}

export default function AcceptanceReportDetailHeader({
  acceptanceReport,
  project,
  parentFolders,
}: AcceptanceReportDetailHeaderProps): JSX.Element {
  const { t } = useTranslation();
  const { viewer } = useViewer();

  return (
    <>
      <DocumentDetailBreadcrumbs
        projectId={project.id}
        projectNumber={project.projectNumber}
        parentFolders={parentFolders}
      />
      <AppHeader
        title={
          <div className="qa-acceptance-report-title">
            <Text component="h1" color="muted" variant="body-large" stylex={{ marginBottom: '0' }}>
              {acceptanceReport.name}
            </Text>
            <Text color={getStatusColor(acceptanceReport)}>
              {t('acceptanceReport.status', { status: t(acceptanceReport.status) })}
            </Text>
          </div>
        }
        userName={viewer?.email}
        logoutText={t('pageInnerLayout.logOut')}
        logoutURL={routes.logout.generatePath({})}
      />
    </>
  );
}
