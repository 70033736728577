import { useTranslation } from 'react-i18next';
import { Icon, Link } from '@hs-baumappe/legacy-ui';

interface ProjectsInsufficientRiskAssessmentWarningProps {
  onClick?: () => void;
}

export default function ProjectsInsufficientRiskAssessmentWarning({
  onClick,
}: ProjectsInsufficientRiskAssessmentWarningProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Link
      variant="body-small"
      component="button"
      underline="none"
      color="yellow-700"
      stylex={{ display: 'flex', gap: 'small-3x', alignItems: 'center' }}
      onClick={onClick}
    >
      <Icon name="warning" size={24} />
      <span>{t('projectListPage.insufficientRiskAssessment')}</span>
    </Link>
  );
}
