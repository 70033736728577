import { gql } from '@apollo/client/core';
import AcceptanceReportLogFragment from './AcceptanceReportLog.fragment';
import ConcernLogFragment from './ConcernLog.fragment';
import ConstructionNoteLogFragment from './ConstructionNoteLog.fragment';
import CustomFileLogFragment from './CustomFileLog.fragment';
import DocumentLogFragment from './DocumentLog.fragment';
import DriveFolderLogFragment from './DriveFolderLog.fragment';
import FinancialDocumentLogFragment from './FinancialDocumentLog.fragment';
import ObstructionLogFragment from './ObstructionLog.fragment';
import OfferLogFragment from './OfferLog.fragment';
import OvertimeLogFragment from './OvertimeLog.fragment';
import ProjectLogFragment from './ProjectLog.fragment';
import QsstLogFragment from './QsstLog.fragment';
import RiskAssessmentLogFragment from './RiskAssessmentLog.fragment';

const LogFragment = gql`
  fragment LogFragment on Logs {
    logs {
      id

      ...AcceptanceReportLog
      ...ConcernLog
      ...ConstructionNoteLog
      ...CustomFileLog
      ...DocumentLog
      ...DriveFolderLog
      ...FinancialDocumentLog
      ...ObstructionLog
      ...OfferLog
      ...OvertimeLog
      ...ProjectLog
      ...QsstLog
      ...RiskAssessmentLog
    }

    pagination {
      current
      hasNextPage
    }
  }

  ${AcceptanceReportLogFragment}
  ${ConcernLogFragment}
  ${ConstructionNoteLogFragment}
  ${CustomFileLogFragment}
  ${DocumentLogFragment}
  ${DriveFolderLogFragment}
  ${FinancialDocumentLogFragment}
  ${ObstructionLogFragment}
  ${OfferLogFragment}
  ${OvertimeLogFragment}
  ${ProjectLogFragment}
  ${QsstLogFragment}
  ${RiskAssessmentLogFragment}
`;

export default LogFragment;
