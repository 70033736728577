import {
  Box,
  Icon,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
} from '@hs-baumappe/legacy-ui';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { projectRoutes } from '../../../../../routes';
import { ProjectDetailLocationState } from '../../../ProjectDetail.route.types';
import { ContactPerson } from '../graphql/__generated__/ContactPerson.fragment';

export interface ContactPeopleTableProps {
  projectId: string;
  contactPeople: ContactPerson[];
  viewerCanChangeProjectData: boolean;
}

export default function ContactPeopleTable({
  projectId,
  contactPeople,
  viewerCanChangeProjectData,
}: ContactPeopleTableProps): JSX.Element | null {
  const { t } = useTranslation();
  const location = useLocation<ProjectDetailLocationState>();

  return (
    <Paper stylex={{ padding: 'small-x' }}>
      <Box stylex={{ overflow: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell component="th" stylex={{ whiteSpace: 'nowrap' }}>
                {t('client.person')}
              </TableCell>
              <TableCell component="th" stylex={{ whiteSpace: 'nowrap' }}>
                {t('contactPeopleTable.note')}
              </TableCell>
              <TableCell component="th" stylex={{ whiteSpace: 'nowrap' }}>
                {t('contactPeopleTable.email')}
              </TableCell>
              <TableCell component="th" stylex={{ whiteSpace: 'nowrap' }}>
                {t('contactPeopleTable.phone')}
              </TableCell>
              <TableCell component="th" />
            </TableRow>
          </TableHead>
          <TableBody>
            {contactPeople.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={5}
                  disablePadding
                  stylex={{ textAlign: 'center', paddingEnds: 'large', paddingSides: 'small-2x' }}
                >
                  <Text variant="body-large" color="gray-500">
                    {t('dataTable.emptyText')}
                  </Text>
                </TableCell>
              </TableRow>
            )}

            {contactPeople.length > 0 &&
              contactPeople.map((contactPerson) => (
                <TableRow key={contactPerson.id}>
                  <TableCell>{contactPerson.name}</TableCell>
                  <TableCell>{contactPerson.note}</TableCell>
                  <TableCell>{contactPerson.email}</TableCell>
                  <TableCell>
                    {contactPerson.phones && (
                      <Box component="ul" stylex={{ listStyle: 'none', padding: '0' }}>
                        {contactPerson.phones.map((phone, index) => (
                          <li key={index}>{phone}</li>
                        ))}
                      </Box>
                    )}
                  </TableCell>
                  <TableCell stylex={{ textAlign: 'right' }}>
                    {viewerCanChangeProjectData ? (
                      <IconButton
                        component={Link}
                        icon={<Icon name="create" />}
                        to={projectRoutes.updateContactPeople.generatePath({
                          projectId,
                          contactPersonId: contactPerson.id,
                        })}
                        state={{
                          backgroundLocation: location,
                        }}
                      />
                    ) : (
                      <IconButton icon={<Icon name="create" />} disabled />
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
    </Paper>
  );
}
