import { TypedDocumentNode, gql } from '@apollo/client';
import { FinancialDocumentLog } from './__generated__/FinancialDocumentLog.fragment';

const FinancialDocumentLogFragment: TypedDocumentNode<FinancialDocumentLog> = gql`
  fragment FinancialDocumentLog on FinancialDocumentLog {
    ... on FinancialDocumentImportedLog {
      id
      name
      date

      projectId

      financialFolderId: folderId
      financialFolderName: folderName
      folderSubType

      actorId
      actorFirstName
      actorLastName
    }

    ... on FinancialDocumentEmailSentLog {
      id
      name
      date

      projectId
      financialDocumentId
      emails

      actorId
      actorFirstName
      actorLastName
    }
  }
`;

export default FinancialDocumentLogFragment;
