import { ElementType, ComponentPropsWithoutRef, ReactNode } from 'react';
import { Box, Icon, Text } from '@hs-baumappe/legacy-ui';
import cx from 'classnames';

import styles from './CardButton.module.scss';

export const DEFAULT_ELEMENT = 'button';

interface CardButtonOwnProps {
  title: ReactNode;
  imageSrc: JSX.IntrinsicElements['img']['src'];
  cornerBadgeText?: ReactNode;
  cornerBadgeColor?: 'default' | 'primary';
  disabled?: boolean;
  status?: 'default' | 'success' | 'warning' | 'error';
  statusContent?: ReactNode;
}

interface WithComponent<T> extends CardButtonOwnProps {
  component?: T;
}

export type CardButtonProps<T extends ElementType = typeof DEFAULT_ELEMENT> = Omit<
  ComponentPropsWithoutRef<T>,
  'title'
> &
  WithComponent<T>;

export default function CardButton<T extends ElementType = typeof DEFAULT_ELEMENT>({
  className,
  title,
  imageSrc,
  cornerBadgeText,
  cornerBadgeColor = 'default',
  disabled,
  component,
  status = 'default',
  statusContent,
  ...otherProps
}: CardButtonProps<T>): JSX.Element {
  const Component = component || DEFAULT_ELEMENT;

  const disabledProp =
    Component === 'button' ? { disabled: disabled } : { 'aria-disabled': disabled };

  return (
    <Component
      className={cx(
        styles['card-button'],
        { [styles[`card-button--status-${status}`]]: status !== 'default' },
        className,
      )}
      {...otherProps}
      {...disabledProp}
    >
      {status !== 'default' && (
        <Box className={styles['card-button__status-badge']}>
          <Box className={styles['card-button__status-icon-container']}>
            {status === 'error' && <Icon name="close" />}
            {status === 'warning' && <Icon name="warning" size="12" />}
            {status === 'success' && <Icon name="check" />}
          </Box>
          {statusContent}
        </Box>
      )}
      <img className={styles['card-button__image']} src={imageSrc} alt="" aria-hidden="true" />
      <Text className={styles['card-button__title']} variant="label-medium">
        {title}
      </Text>
      {cornerBadgeText && (
        <Text
          component="div"
          variant="label-medium"
          className={cx(
            styles['card-button__corner-badge'],
            styles[`card-button__corner-badge--color-${cornerBadgeColor}`],
          )}
        >
          {cornerBadgeText}
        </Text>
      )}
    </Component>
  );
}
