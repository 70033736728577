import { gql } from '@apollo/client';

const AcceptanceReportFragment = gql`
  fragment AcceptanceReport on AcceptanceReport {
    id

    name
    pdfPath
    manualPdfPath
    status
    no

    hasDefect
    defect {
      agreed
    }

    viewerCanUpdate
    viewerCanAssign
    viewerCanArchive
    viewerCanSign
    viewerCanRemove
    viewerCanSendExternalLink
    viewerCanUploadManualPdf
    viewerCanRemoveManualPdf

    signatures {
      id
      type
    }
  }
`;

export default AcceptanceReportFragment;
