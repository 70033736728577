import { useCallback, useMemo, useState } from 'react';
import { isProjectRiskAssessmentInsufficient } from '../Projects.utils';
import { SearchedProject } from './ProjectFilter/__generated__/graphql';

interface UseProjectsInsufficientRiskAssessment {
  showInsufficientRiskAssessmentWarning: boolean;
  scrollToInsufficientRiskAssessmentProject: () => void;
}

interface UseProjectsInsufficientRiskAssessmentOptions {
  loading: boolean;
  nextPageLoading: boolean;
  projects: SearchedProject['projects']['projects'];
}

export default function useProjectsInsufficientRiskAssessment({
  projects,
  loading,
  nextPageLoading,
}: UseProjectsInsufficientRiskAssessmentOptions): UseProjectsInsufficientRiskAssessment {
  const [projectIndex, setProjectIndex] = useState<number>(0);

  const insufficientProjects = useMemo(
    () => projects.filter(isProjectRiskAssessmentInsufficient),
    [projects],
  );

  const scrollToInsufficientRiskAssessmentProject = useCallback(() => {
    const targetElement = document.getElementById(insufficientProjects[projectIndex].id);
    targetElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });

    setProjectIndex((prevIndex) => (prevIndex + 1) % insufficientProjects.length);
  }, [projectIndex, insufficientProjects]);

  return {
    showInsufficientRiskAssessmentWarning:
      !loading && !nextPageLoading && insufficientProjects.length > 0,
    scrollToInsufficientRiskAssessmentProject,
  };
}
