import { isApolloError, useMutation } from '@apollo/client';
import { FormikErrors, FormikHelpers } from 'formik/dist/types';
import {
  ContactPeopleUpdate,
  ContactPeopleUpdateVariables,
} from './graphql/__generated__/ContactPeopleUpdate.mutation';
import { AddContactPersonFormValues } from '../AddContactPeople/AddContactPersonForm/AddContactForm.types';
import { getErrorsFromServerError } from '../../../../apollo/errors';
import { ContactPersonAddInput } from '../../../../globalTypes';
import ContactPeopleUpdateMutation from './graphql/ContactPeopleUpdate.mutation';
import {
  ContactPeopleRemove,
  ContactPeopleRemoveVariables,
} from './graphql/__generated__/ContactPeopleRemove.mutation';
import ContactPeopleRemoveMutation from './graphql/ContactPeopleRemove.mutation';

interface UseUpdateContactPersonResponse {
  updateContactPerson: (
    values: AddContactPersonFormValues,
    formikHelpers: FormikHelpers<AddContactPersonFormValues>,
  ) => void;
  removeContactPerson: () => void;
}

interface UseContactPersonServiceOptions {
  contactPersonId: string;
  onSuccess: () => void;
}

export default function useContactPersonService({
  contactPersonId,
  onSuccess,
}: UseContactPersonServiceOptions): UseUpdateContactPersonResponse {
  const [update, updateResult] = useMutation<ContactPeopleUpdate, ContactPeopleUpdateVariables>(
    ContactPeopleUpdateMutation,
  );
  const [remove, removeResult] = useMutation<ContactPeopleRemove, ContactPeopleRemoveVariables>(
    ContactPeopleRemoveMutation,
  );

  async function updateContactPerson(
    values: AddContactPersonFormValues,
    formikHelpers: FormikHelpers<AddContactPersonFormValues>,
  ) {
    if (updateResult.loading) {
      return;
    }

    try {
      await update({
        variables: {
          input: {
            id: contactPersonId,
            name: values.name,
            email: values.email,
            phones: values.phones.filter(Boolean),
            note: values.note,
          },
        },
      });

      onSuccess();
    } catch (e) {
      if (!(e instanceof Error) || !isApolloError(e)) {
        return;
      }

      const formErrors = getErrorsFromServerError<
        FormikErrors<AddContactPersonFormValues>,
        ContactPersonAddInput
      >(e, (errors) => errors);
      if (!formErrors) {
        return;
      }

      formikHelpers.setErrors(formErrors);
    }
  }

  async function removeContactPerson() {
    if (removeResult.loading) {
      return;
    }

    await remove({
      variables: {
        input: {
          id: contactPersonId,
        },
      },
      update: (cache) => {
        cache.evict({
          id: cache.identify({
            id: contactPersonId,
            __typename: 'ContactPerson',
          }),
        });
      },
    });

    onSuccess();
  }

  return {
    updateContactPerson,
    removeContactPerson,
  };
}
