import { Button, Icon, Stack } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import DriveDocumentNoteContainer from '../../../../../components/DriveDocumentNoteContainer';
import { CustomFileDocument } from '../../../graphql/__generated__/CustomFileDocument.fragment';
import { CustomFileDocumentScopes } from '../../../Document.types';

interface DocumentDetailSidebarTopProps {
  document: CustomFileDocument;
  scopes: CustomFileDocumentScopes;
  onSaveButtonClick: () => void;
  saving: boolean;
  downloading: boolean;
  hasUnsavedChanges: boolean;
  onSendButtonClick: () => void;
  onDownloadButtonClick: () => void;
  readOnly: boolean;
}

export default function DocumentDetailSidebarTop({
  document,
  scopes,
  onSaveButtonClick,
  saving,
  downloading,
  hasUnsavedChanges,
  onSendButtonClick,
  onDownloadButtonClick,
  readOnly,
}: DocumentDetailSidebarTopProps): JSX.Element {
  const { t } = useTranslation();

  const updatePermitted = scopes.updateCustomFileDocument.isPermitted;
  const sendPermitted = scopes.sendCustomFileDocumentAttachment.isPermitted;
  const downloadPermitted = scopes.downloadCustomFileDocument.isPermitted;

  return (
    <Stack gap="small-3x">
      <DriveDocumentNoteContainer id={document.id} name={document.name}>
        {updatePermitted && !readOnly && (
          <Button
            className="qa-custom-file-document-save"
            color="primary"
            endIcon={<Icon name="save" />}
            onClick={onSaveButtonClick}
            loading={saving}
            disabled={!hasUnsavedChanges}
          >
            {t('customFile.documentDetail.sidebar.actions.saveButtonText')}
          </Button>
        )}

        {sendPermitted && (
          <Button
            className="qa-custom-file-document-send-email"
            endIcon={<Icon name="email" />}
            onClick={onSendButtonClick}
          >
            {t('customFile.documentDetail.sidebar.actions.sendPDFButtonText')}
          </Button>
        )}

        {downloadPermitted && (
          <Button
            className="qa-custom-file-document-download"
            endIcon={<Icon name="cloud-download" />}
            onClick={onDownloadButtonClick}
            loading={downloading}
          >
            {t('customFile.documentDetail.sidebar.actions.downloadButtonText')}
          </Button>
        )}
      </DriveDocumentNoteContainer>
    </Stack>
  );
}
