import { useField } from 'formik';
import { FormikFormField } from '@hs-baumappe/forms';
import { useTranslation } from 'react-i18next';
import { Box, Button, Flex, Icon, IconButton, Stack } from '@hs-baumappe/legacy-ui';

const CONTACT_PERSON_MAX_PHONE_COUNT = 3;

export default function AddContactPersonFormPhones(): JSX.Element {
  const [, { value }, { setValue }] = useField<string[]>('phones');
  const { t } = useTranslation(undefined, { keyPrefix: 'contactPeople.phone' });

  function handleAddPhoneButtonClick() {
    setValue([...value, '']);
  }

  function handleRemovePhoneButtonClick(index: number) {
    setValue([...value].toSpliced(index, 1), true);
  }

  return (
    <Stack gap="small-x">
      {value.map((_, index) => (
        <Flex key={index} alignItems="flex-start" gap="small-3x">
          <Box stylex={{ flexGrow: '1' }}>
            <FormikFormField
              name={`phones[${index}]`}
              label={t('label', { no: value.length > 1 ? index + 1 : '' })}
              placeholder={t('placeholder')}
            />
          </Box>

          {index > 0 && (
            <IconButton
              icon={<Icon name="delete" color="inherit" />}
              color="error"
              type="button"
              onClick={() => handleRemovePhoneButtonClick(index)}
              stylex={{ marginTop: 'small-x' }}
            />
          )}
        </Flex>
      ))}

      {value.length < CONTACT_PERSON_MAX_PHONE_COUNT && (
        <Button
          type="button"
          variant="text"
          startIcon={<Icon name="add" />}
          color="primary"
          stylex={{ alignSelf: 'flex-end' }}
          onClick={handleAddPhoneButtonClick}
        >
          {t('addPhoneButton')}
        </Button>
      )}
    </Stack>
  );
}
