import { useState } from 'react';
import { createPath, useNavigate } from 'react-router-dom';
import { Icon, IconButton, useSnackbar } from '@hs-baumappe/legacy-ui';
import { ApolloError, isApolloError, useMutation } from '@apollo/client';
import { isGraphQLFileSizeLimitExceededFormattedError } from '@hs-baumappe/graphql-errors';
import { FormikHelpers, FormikErrors } from 'formik';

import CustomFileDocumentEmailSendMutation from './graphql/CustomFileDocumentEmailSend.mutation';
import { CustomFileDocumentSendEmailVariables } from './graphql/__generated__/CustomFileDocumentEmailSend.mutation';
import { DocumentDetailAlert } from '../../../DocumentDetail/DocumentDetail.utils';
import { EmailFormValues } from '../../../../../containers/Email';
import { getErrorsFromServerError } from '../../../../../apollo/errors';
import documentRoutes from '../../../documentRoutes';
import CustomFileDocumentDetailQuery from '../../../graphql/CustomFileDocumentDetail.query';
import { useTranslation } from 'react-i18next';

interface UseDocumentSendEmailResponse {
  sendEmail: (
    values: EmailFormValues,
    formikHelpers: FormikHelpers<EmailFormValues>,
  ) => Promise<void>;
  loading: boolean;
  error?: ApolloError;
}

export default function useCustomFileDocumentSendEmail(
  documentId: string,
): UseDocumentSendEmailResponse {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { open: openSnackbar, close: closeSnackbar } = useSnackbar();

  const [error, setError] = useState<ApolloError>();
  const [mutation, { loading }] = useMutation(CustomFileDocumentEmailSendMutation, {
    refetchQueries: [{ query: CustomFileDocumentDetailQuery, variables: { id: documentId } }],
  });

  async function sendEmail(values: EmailFormValues, formikHelpers: FormikHelpers<EmailFormValues>) {
    if (loading) {
      return;
    }

    try {
      await mutation({
        variables: {
          input: {
            id: documentId,
            to: values.toEmails,
            subject: values.subject,
            content: values.content,
            cc: values.ccEmails,
          },
        },
      });

      const searchParams = new URLSearchParams({ alert: DocumentDetailAlert.SEND_EMAIL });

      navigate(
        createPath({
          pathname: documentRoutes.manipulate.generatePath({ documentId }),
          search: searchParams.toString(),
        }),
      );
    } catch (e) {
      if (!(e instanceof Error) || !isApolloError(e)) {
        return;
      }

      const [graphqlError] = e.graphQLErrors;
      if (isGraphQLFileSizeLimitExceededFormattedError(graphqlError)) {
        const {
          extensions: { details },
        } = graphqlError;

        const errorMessage = t('validation.email.attachmentLimitExceeded', {
          limit: details.limit,
          unit: t(`sizeUnit.${details.unit}`),
        });

        openSnackbar(errorMessage, {
          type: 'error',
          action: (id) => (
            <IconButton
              color="inherit"
              icon={<Icon name="close" />}
              onClick={() => closeSnackbar(id)}
            />
          ),
        });
        return;
      }

      const formErrors = getErrorsFromServerError<
        FormikErrors<EmailFormValues>,
        CustomFileDocumentSendEmailVariables['input']
      >(e, (errors) => ({
        ...errors,
        ccEmails: errors.cc,
        toEmails: errors.to,
      }));

      if (!formErrors) {
        setError(e);
        return;
      }

      formikHelpers.setErrors(formErrors);
    }
  }

  return {
    sendEmail,
    loading,
    error,
  };
}
