import AcceptanceReportLog, { isAcceptanceReportLog } from './AcceptanceReportLog';
import ConcernLog, { isConcernLog } from './ConcernLog';
import ConstructionNoteLog, { isConstructionNoteLog } from './ConstructionNoteLog';
import CustomFileLog, { isCustomFileLog } from './CustomFileLog';
import DocumentLog, { isDocumentLog } from './DocumentLog';
import DriveFolderLog, { isDriveFolderLog } from './DriveFolderLog';
import FinancialDocumentLog, { isFinancialDocumentLog } from './FinancialDocumentLog';
import ObstructionLog, { isObstructionLog } from './ObstructionLog';
import OfferLog, { isOfferLog } from './OfferLog';
import ProjectLog, { isProjectLog } from './ProjectLog';
import OvertimeLog, { isOvertimeLog } from './OvertimeLog';
import QsstLog, { isQsstLog } from './QsstLog';
import RiskAssessmentLog, { isRiskAssessmentLog } from './RiskAssessmentLog';
import { Log } from './Log.types';

interface Actor {
  actorFirstName: string;
  actorLastName: string;
}

type LogWithActor = Extract<Log, Actor>;

export function getActor(log: LogWithActor): Actor {
  return {
    actorFirstName: log.actorFirstName,
    actorLastName: log.actorLastName,
  };
}

export function assertUnknownLog(log: never): never;
export function assertUnknownLog(log: Log): never {
  throw new Error(`Unknown log type: ${log.__typename}`);
}

export function renderLogMessage(log: Log): JSX.Element {
  if (isAcceptanceReportLog(log)) {
    return <AcceptanceReportLog log={log} />;
  }

  if (isConcernLog(log)) {
    return <ConcernLog log={log} />;
  }

  if (isConstructionNoteLog(log)) {
    return <ConstructionNoteLog log={log} />;
  }

  if (isCustomFileLog(log)) {
    return <CustomFileLog log={log} />;
  }

  if (isDocumentLog(log)) {
    return <DocumentLog log={log} />;
  }

  if (isDriveFolderLog(log)) {
    return <DriveFolderLog log={log} />;
  }

  if (isFinancialDocumentLog(log)) {
    return <FinancialDocumentLog log={log} />;
  }

  if (isObstructionLog(log)) {
    return <ObstructionLog log={log} />;
  }

  if (isOfferLog(log)) {
    return <OfferLog log={log} />;
  }

  if (isOvertimeLog(log)) {
    return <OvertimeLog log={log} />;
  }

  if (isProjectLog(log)) {
    return <ProjectLog log={log} />;
  }

  if (isQsstLog(log)) {
    return <QsstLog log={log} />;
  }

  if (isRiskAssessmentLog(log)) {
    return <RiskAssessmentLog log={log} />;
  }

  return assertUnknownLog(log);
}
