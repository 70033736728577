import { Text } from '@hs-baumappe/legacy-ui';
import { ComponentProps } from 'react';
import { Trans } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { ProjectDetailLocationState } from '../../../../routes/Project/ProjectDetail/ProjectDetail.route.types';
import { projectRoutes } from '../../../../routes/routes';
import { FinancialDocumentLog as FinancialDocumentLogType } from '../../graphql/__generated__/FinancialDocumentLog.fragment';
import { getActor } from '../Log.utils';
import LogActor from '../LogActor';
import LogEmails from '../LogEmails';

interface FinancialDocumentLogProps extends ComponentProps<typeof Text> {
  log: FinancialDocumentLogType;
}

export default function FinancialDocumentLog({
  log,
  ...props
}: FinancialDocumentLogProps): JSX.Element {
  switch (log.__typename) {
    case 'FinancialDocumentImportedLog':
      return (
        <Text variant="body-small" {...props}>
          <Trans
            i18nKey="logs.financialDocumentImported"
            values={{
              documentName: log.name,
              folderName: log.financialFolderName,
            }}
            components={{
              actor: <LogActor actor={getActor(log)} />,
              bold: <Text variant="label-medium" />,
              folderLink: (
                <RouterLink
                  to={projectRoutes.driveWithFolder.generatePath({
                    projectId: log.projectId,
                    folderId: log.financialFolderId,
                  })}
                  state={{ scrollToDrive: true } as ProjectDetailLocationState}
                />
              ),
            }}
          />
        </Text>
      );

    case 'FinancialDocumentEmailSentLog':
      return (
        <Text variant="body-small" {...props}>
          <Trans
            i18nKey="logs.financialDocumentEmailSent"
            values={{ documentName: log.name }}
            components={{
              bold: <Text variant="label-medium" />,
              actor: <LogActor actor={getActor(log)} />,
              emails: <LogEmails emails={log.emails} />,
            }}
          />
        </Text>
      );

    default:
      throw new Error(`Unknown financial document log: ${log}`);
  }
}
