import { RiskAssessmentRegulationStatus } from '../../globalTypes';
import { SearchedProject } from './Detail/ProjectFilter/__generated__/graphql';

export function isProjectRiskAssessmentInsufficient(
  project: SearchedProject['projects']['projects'][number],
): boolean {
  switch (project?.riskAssessmentRegulation?.status) {
    case RiskAssessmentRegulationStatus.MISSING_SIGNATURE:
    case RiskAssessmentRegulationStatus.NO_RISK_ASSESSMENT:
      return true;
    default:
      return false;
  }
}
