import { useState } from 'react';

import { CustomFileDocument } from '../../graphql/__generated__/CustomFileDocument.fragment';

interface UseDownloadCustomFileDocumentResponse {
  download: (document: CustomFileDocument) => Promise<void>;
  loading: boolean;
}

export default function useDownloadCustomFileDocument(): UseDownloadCustomFileDocumentResponse {
  const [loading, setLoading] = useState(false);

  async function download(customFileDocument: CustomFileDocument) {
    if (!customFileDocument.download) return;

    const { name, url } = customFileDocument.download;

    setLoading(true);

    try {
      const documentAsBlob = await fetch(url).then((response) => response.blob());

      const objectUrl = URL.createObjectURL(documentAsBlob);
      const anchor = document.createElement('a');

      anchor.download = name || '';
      anchor.href = objectUrl;
      anchor.click();
      anchor.remove();

      URL.revokeObjectURL(objectUrl);
    } finally {
      setLoading(false);
    }
  }

  return {
    download,
    loading,
  };
}
