import { SchemaOf } from 'yup';
import yup from '../../../../../yup';
import { AddContactPersonFormValues } from './AddContactForm.types';
import i18n from '../../../../../i18n';

const addContactPersonFormValidationSchema: SchemaOf<AddContactPersonFormValues> = yup.object({
  email: yup
    .string()
    .email()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .when(['name'], (name: string, schema: any) => {
      if (!name) {
        return schema.required();
      }

      return schema;
    }),
  name: yup.string(),
  note: yup.string(),
  phones: yup
    .array()
    .of(yup.string().defined().default('').max(16, i18n.t('contactPeople.phone.maxLengthError'))),
});

export default addContactPersonFormValidationSchema;
